import React from 'react';
import * as R from 'ramda';
import styled, { css } from 'styled-components';
import { getThemeColor } from '@poly/admin-book';
import { PatternFormat } from 'react-number-format';
import { bool, func, string } from 'prop-types';
import { useInternationalPhoneLogic } from '@poly/client-utils';

const inputStyles = css`
  width: 100%;
  max-height: 40px;
  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: rgb(94, 98, 113);
  background-color: transparent;
  padding: 10px 15px;
  line-height: normal;
  border: 1px solid;
  border-color: ${getThemeColor(['lightGrey'])};
  border-radius: 3px;
  margin-bottom: 15px;
  &:focus-visible {
    outline: 2px solid;
    outline-color: ${({ branding }) =>
      getThemeColor([branding, 'primaryColor'])};
  }
`;

export const InputError = styled.div`
  color: #ff0000;
  opacity: 0.8;
  font-size: 12px;
  margin-bottom: 5px;
  margin-top: -10px;
`;

const InputS = styled.input`
  ${inputStyles}
`;

export function Input({ hasError, error, ...props }) {
  return (
    <div>
      <InputS {...props} />
      {hasError && error && <InputError>{error}</InputError>}
    </div>
  );
}

Input.propTypes = {
  hasError: bool,
  error: string,
};

const TextAreaS = styled(InputS.withComponent('textarea'))`
  min-height: 100px;
  margin-bottom: 15px;
`;

export function TextArea({ hasError, error, ...props }) {
  return (
    <>
      <TextAreaS {...props} />
      {hasError && error && <InputError>{error}</InputError>}
    </>
  );
}

TextArea.propTypes = {
  hasError: bool,
  error: string,
};

const NumberInput = styled(PatternFormat)`
  ${inputStyles};
`;

export function PhoneInput({
  value,
  onChange,
  onFocus,
  placeholder,
  error,
  hasError,
}) {
  const inputProps = useInternationalPhoneLogic({ value, onChange, onFocus });

  return (
    <div>
      <NumberInput placeholder={placeholder} {...inputProps} />
      {hasError && error && <InputError>{error}</InputError>}
    </div>
  );
}

PhoneInput.propTypes = {
  value: string.isRequired,
  onChange: func.isRequired,
  onFocus: func,
  hasError: bool,
  error: string,
};

PhoneInput.defaultProps = {
  onFocus: R.T,
};

PhoneInput.propTypes = {
  onChange: func.isRequired,
  value: string.isRequired,
  placeholder: string.isRequired,
};

import React from 'react';
import { ClientBranding } from '@poly/constants';

import {
  createGlobalStyle,
  ThemeProvider as ThemeProviderBase,
} from 'styled-components';
import { node } from 'prop-types';

const defaultTheme = {
  colors: {
    offWhite: 'rgb(252, 252, 252)',
    lightGrey: 'rgb(227, 229, 237)',
    [ClientBranding.POLY]: {
      primaryColor: 'rgb(23, 65, 152)',
      secondaryColor: 'rgb(255, 140, 0)',
      secondaryColorLight: 'rgb(248, 169, 87)',
    },
    [ClientBranding.ESFMX]: {
      primaryColor: '#8BC15D',
      secondaryColor: '#1b657f',
    },
  },
  fonts: {
    defaultFont: 'Montserrat, sans-serif',
  },
};

const GlobalStyle = createGlobalStyle`
    body {
      font-family: 'Montserrat', sans-serif;
    }
`;

export function ThemeProvider({ children }) {
  return (
    <ThemeProviderBase theme={defaultTheme}>
      <GlobalStyle />
      {children}
    </ThemeProviderBase>
  );
}

ThemeProvider.propTypes = {
  children: node.isRequired,
};

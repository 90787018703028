import React, { useContext } from 'react';
import * as R from 'ramda';
import { useDropzone } from 'react-dropzone';
import { Icon, getThemeColor, prepareUploadFiles } from '@poly/admin-book';
import styled, { ThemeContext } from 'styled-components';
import { func, string, shape, arrayOf } from 'prop-types';
import { propEqLegacy } from '@poly/utils';

import { InputError } from './Inputs.js';

const DropzoneContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid rgb(227, 229, 237);
  cursor: pointer;
`;

const TextS = styled.p`
  font-size: 14px;
  color: rgb(94, 98, 113);
  margin-top: 5px;
`;

const ImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;

const ImageS = styled.img`
  height: 50px;
  :hover {
    opacity: 0.8;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: white;
  position: absolute;
  top: calc(50% - 12px);
  right: calc(50% - 12px);
  cursor: pointer;
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  overflow: hidden;
  margin-right: 4px;
  margin-bottom: 4px;
  border: ${({ isError }) => (isError ? '2px solid red' : 'node')};
  position: relative;
  :hover {
    ${IconWrapper} {
      visibility: visible;
    }
  }
`;

const ErrorWrapper = styled.div`
  margin: 20px 0 10px 0;
`;

const ErrorText = styled(InputError)`
  display: block;
  word-break: break-all;
  margin: 5px 0;
`;

// rejectFileById :: String -> [File] -> [File]
const rejectFileById = (id, files) => R.reject(propEqLegacy('_id', id), files);

// isFileError :: (String, [FileError]) -> Boolean
const isFileError = (id, errors) =>
  R.compose(R.complement(R.isNil), R.find(propEqLegacy('fileId', id)))(errors);

function ImagePreview({ image, images, onChange, errors }) {
  const deleteImage = () => onChange(rejectFileById(image._id, images));

  const filePreview = URL.createObjectURL(image.upload);

  const isError = isFileError(image._id, errors);

  return (
    <ImageWrapper isError={isError} title={image.fileName}>
      <ImageS src={filePreview} alt={image.fileName} />
      <IconWrapper onClick={deleteImage}>
        <Icon name="delete" size="16" color="#5e6271" />
      </IconWrapper>
    </ImageWrapper>
  );
}

ImagePreview.propTypes = {
  images: arrayOf(shape({ preview: string })),
  onChange: func.isRequired,
  image: shape({ _id: string, fileName: string }),
  errors: arrayOf(shape({ fileId: string, error: string })),
};

// getFilesErrorsMsg :: ([File], [FileError]) -> [String]
// FileError = {
//  fileId: ID,
//  error: String
// }
const getFilesErrorsMsg = (images, errors) =>
  R.map(
    R.ifElse(
      R.prop('commonError'),
      R.prop('commonError'),
      R.compose(
        R.join(' - '),
        R.juxt([
          R.compose(
            R.prop('fileName'),
            R.find(R.__, images),
            propEqLegacy('_id'),
            R.prop('fileId'),
          ),
          R.prop('error'),
        ]),
      ),
    ),
  )(errors);

function FilePickerErrorsComp({ errors, images }) {
  if (!Array.isArray(errors)) {
    return null;
  }

  const errorsMgs = getFilesErrorsMsg(images, errors);

  return (
    <ErrorWrapper>
      {errorsMgs.map((err) => (
        <ErrorText key={err}>{err}</ErrorText>
      ))}
    </ErrorWrapper>
  );
}

FilePickerErrorsComp.propTypes = {
  errors: arrayOf(shape({ fileId: string, error: string })),
  images: arrayOf(shape({ _id: string, fileName: string })),
};

export function FilePicker({ onChange, branding, value, meta }) {
  const theme = useContext(ThemeContext);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/*',
    onDrop: (images) => {
      onChange(prepareUploadFiles(images));
    },
  });

  const iconColor = getThemeColor([branding, 'secondaryColor'])({ theme });

  return (
    <>
      <DropzoneContainer {...getRootProps()}>
        <input {...getInputProps()} />
        <Icon name="attachImage" color={iconColor} />
        {isDragActive ? (
          <TextS>Drop the files here ...</TextS>
        ) : (
          <TextS>Drag & Drop files here or click to select files</TextS>
        )}
      </DropzoneContainer>
      <ImagesContainer>
        {value.map((image) => (
          <ImagePreview
            key={image._id}
            image={image}
            images={value}
            onChange={onChange}
            errors={meta.error}
          />
        ))}
      </ImagesContainer>
      <FilePickerErrorsComp errors={meta.error} images={value} />
    </>
  );
}

FilePicker.propTypes = {
  onChange: func.isRequired,
  branding: string.isRequired,
  value: arrayOf(shape({ _id: string, fileName: string })),
  meta: shape({ error: arrayOf(shape({ fileId: string, error: string })) }),
};

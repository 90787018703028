import React from 'react';
import styled from 'styled-components';
import { node } from 'prop-types';
import { getThemeColor } from '@poly/admin-book';

const Wrapper = styled.div`
  min-height: 100vh;
  width: 100%;
  background-color: ${getThemeColor(['offWhite'])};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export function CommonLayout({ children }) {
  return <Wrapper>{children}</Wrapper>;
}

CommonLayout.propTypes = {
  children: node.isRequired,
};

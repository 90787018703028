import * as R from 'ramda';

const REQUEST_FORM_VALUES_KEY = 'requestInitialValues';

// getLSRequestFormValues :: _ -> RequestFormValues
// RequestFormValues = { fullName: String, cellPhone: String, email: String }
export const getLSRequestFormValues = () =>
  JSON.parse(localStorage.getItem(REQUEST_FORM_VALUES_KEY));

// postLSRequestFormValues :: RequestFormValues -> _
export const postLSRequestFormValues = (values) => {
  const formValuesAsString = JSON.stringify({
    ...getLSRequestFormValues(),
    ...R.pick(['fullName', 'cellPhone', 'email'], values),
  });

  localStorage.setItem(REQUEST_FORM_VALUES_KEY, formValuesAsString);
};

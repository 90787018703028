import * as R from 'ramda';
import { getThemeColor } from '@poly/admin-book';
import { func, string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { ESFM_INFO, ClientBranding } from '@poly/constants';

import { Button, PhoneLinkButton } from '../../components/Button.js';
import { Text } from '../../components/Text.js';

const ProjectNumber = styled.p`
  display: inline-block;
  font-size: 18px;
  font-weight: bold;
  color: ${({ branding }) => getThemeColor([branding, 'primaryColor'])};

  margin: 2px 0;
`;

export function ContactPhoneButton({ branding, ...props }) {
  const phoneNumber = R.replace(/-/g, '.', ESFM_INFO.telephone);
  const hrefNumber = R.replace(/-/g, '', ESFM_INFO.telephone);
  return (
    <PhoneLinkButton {...props} branding={branding} href={`tel:${hrefNumber}`}>
      {phoneNumber}
    </PhoneLinkButton>
  );
}

ContactPhoneButton.propTypes = {
  branding: string,
};

ContactPhoneButton.defaultProps = {
  branding: ClientBranding.POLY,
};

export function CreatedRequest({ branding, handleClick, projectId }) {
  return (
    <div style={{ textAlign: 'center' }}>
      {projectId ? (
        <>
          <Text>Project Number:</Text>
          <ProjectNumber branding={branding}>{projectId}</ProjectNumber>
          <Text>
            Thank you for your submission. We will reach out shortly if we need
            additional information.
          </Text>
        </>
      ) : (
        <Text>
          We need to verify your submission. Please call us
          <ContactPhoneButton branding={branding} />
        </Text>
      )}
      <div className="form-actions">
        <Button branding={branding} onClick={handleClick}>
          New Request
        </Button>
      </div>
    </div>
  );
}

CreatedRequest.propTypes = {
  branding: string.isRequired,
  handleClick: func.isRequired,
  projectId: string,
};

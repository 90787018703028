import React from 'react';
import * as R from 'ramda';
import { ApolloProvider } from '@apollo/client';
import { func, node, oneOfType } from 'prop-types';
import { handleApplicationMaintenance } from '@poly/client-utils';
import { createApolloClient } from '@poly/apollo-client';
import { getSentryApolloLinks } from '@poly/client-utils/src/sentry/sentry-link.js';

const handleApolloError = R.identity;
const redirectPageHandler = R.identity;

const apolloClient = createApolloClient(
  redirectPageHandler,
  handleApolloError,
  handleApplicationMaintenance,
  { additionalAfterWareLinks: getSentryApolloLinks(process.env.SENTRY_DSN) },
);

export function ApolloClientProvider({ children }) {
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
}

ApolloClientProvider.propTypes = {
  children: oneOfType([node, func]).isRequired,
};

import React, { useCallback, useState } from 'react';
import * as R from 'ramda';
import { useQuery, gql } from '@apollo/client';
import { debounce } from '@poly/utils/src/general.js';
import { propertyStatuses } from '@poly/constants/src/properties.js';

import { Select } from './Select.js';

const SEARCH_REQUESTS_PROPERTIES_QUERY = gql`
  query SEARCH_REQUESTS_PROPERTIES_QUERY($input: CollectionSearchParams) {
    searchRequestsProperties(input: $input) {
      hits {
        _id
        address {
          formatted_address
        }
      }
    }
  }
`;

// preparePropertiesOptions :: SearchRequestsPropertiesResult -> [Option]
const preparePropertiesOptions = R.compose(
  R.map(
    R.applySpec({
      label: R.path(['address', 'formatted_address']),
      value: R.prop('_id'),
    }),
  ),
  R.pathOr([], ['searchRequestsProperties', 'hits']),
);

export function PropertySelect({ ...props }) {
  const [searchTerm, setSearchTerm] = useState('');

  const debouncedSearch = useCallback(
    debounce(300)((search) => {
      setSearchTerm(search);
    }),
    [],
  );

  const { data } = useQuery(SEARCH_REQUESTS_PROPERTIES_QUERY, {
    variables: {
      input: {
        searchTerm,
        query: { term: { status: propertyStatuses.ACTIVE } },
        size: 10000,
      },
    },
    fetchPolicy: 'network-only',
  });

  const options = preparePropertiesOptions(data);

  const selectProps = {
    ...props,
    options,
    onInputChange: debouncedSearch,
    placeholder: 'Location',
  };

  return <Select {...selectProps} />;
}

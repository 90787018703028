import React from 'react';
import styled from 'styled-components';
import { ContactPhoneButton } from './RequestFormPage/CreatedRequest.js';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 10px;
`;

const Text = styled.span`
  font-size: 1.5em;
  margin-bottom: 15px;
`;

export function NotFoundPage() {
  return (
    <Wrapper>
      <Text>Something went wrong. </Text>
      <Text>Please call our national service center.</Text>
      <ContactPhoneButton fontSize="25px" />
    </Wrapper>
  );
}

import * as R from 'ramda';
import { useQuery, gql } from '@apollo/client';
import { ClientBranding } from '@poly/constants';

import { ESFMX_LOGO_PATH } from '../../components/PlatformLogo.js';

const requestsInfoByTokenQuery = gql`
  query requestsInfoByToken {
    requestsInfoByToken {
      property {
        _id
        name
      }
      client {
        _id
        configs {
          logoImage {
            url
          }
          requestsApp {
            branding
          }
          projectPriorities {
            id
            priority
            amount
            unit
          }
        }
      }
    }
  }
`;

// getRequestInfoByQueryData :: QueryData -> PropertyInfo
// PropertyInfo = {
//   propertyName: String,
//   clientName: String,
//   branding: String,
//   logoImageUrl: String
// }
const getRequestInfoByQueryData = R.compose(
  R.applySpec({
    propertyName: R.pathOr('', ['property', 'name']),
    branding: R.compose(
      R.defaultTo(ClientBranding.ESFMX),
      R.path(['client', 'configs', 'requestsApp', 'branding']),
    ),
    logoImageUrl: R.pathOr(ESFMX_LOGO_PATH, [
      'client',
      'configs',
      'logoImage',
      'url',
    ]),
    projectPriorities: R.pathOr([], ['client', 'configs', 'projectPriorities']),
  }),
  R.prop('requestsInfoByToken'),
);

export const useRequestInfoByTokenData = (isAuthorized) => {
  const { data, loading, error } = useQuery(requestsInfoByTokenQuery, {
    skip: !isAuthorized,
  });

  return {
    ...getRequestInfoByQueryData(data),
    loading,
    error,
  };
};

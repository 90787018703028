import React from 'react';
import { RoutesGenerator } from '@poly/client-routing';

import { CommonLayout } from './layouts/CommmonLayout.js';
import { routes } from './routes.js';
import { RequestFormPage } from './pages/RequestFormPage/RequestFormPage.js';
import { NotFoundPage } from './pages/NotFoundPage.js';

const routerConfig = [
  {
    layout: CommonLayout,
    routes: [
      [routes.submit, RequestFormPage],
      ['/*', NotFoundPage],
    ],
  },
];

export function Router() {
  return <RoutesGenerator config={routerConfig} />;
}

import { useState, useMemo, useEffect } from 'react';

import { internationalPhoneUtils } from './internationalPhoneUtils.js';

const {
  findIsoCountryByNumber,
  isPhoneNumberNotHasCountryCode,
  isPhoneNumberEntered,
  getCountryByIso,
  getPhonePattern,
} = internationalPhoneUtils;

export const useInternationalPhoneLogic = ({ value, onChange, onFocus }) => {
  const [internalValue, setInternalValue] = useState(value);

  const isoByNumber = !isPhoneNumberNotHasCountryCode(internalValue)
    ? findIsoCountryByNumber(internalValue)
    : undefined;

  const [countryIso, setCountryIso] = useState(isoByNumber);

  const country = useMemo(() => getCountryByIso(countryIso), [countryIso]);

  const phonePattern = isPhoneNumberEntered(internalValue, country.code)
    ? getPhonePattern(country)
    : null;

  const onValueChange = (values) => {
    const { formattedValue } = values;
    setInternalValue(formattedValue);
    if (isPhoneNumberEntered(formattedValue, country.code)) {
      onChange(formattedValue);
    }
  };

  useEffect(() => {
    if (countryIso !== isoByNumber) {
      onChange(country.code);
      setInternalValue(country.code);
    }
  }, [countryIso]);

  useEffect(() => {
    if (isoByNumber) {
      setCountryIso(isoByNumber);
    }
  }, [isoByNumber]);

  useEffect(() => {
    if (isPhoneNumberNotHasCountryCode(internalValue)) {
      const formattedValue = `+${country.code}${value}`;
      setInternalValue(formattedValue);
      onChange(formattedValue);
    }
  }, []);

  const onFocusInternal = (e) => {
    if (!e.target.value) {
      setInternalValue(e.target.placeholder);
    }
    onFocus(e);
  };

  return {
    placeholder: country.placeholder,
    onFocus: onFocusInternal,
    format: country.format,
    type: 'tel',
    mask: '_',
    onValueChange,
    pattern: phonePattern,
    value: internalValue,
  };
};

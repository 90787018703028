import React from 'react';
import { ApolloClientProvider } from './ApolloProvider.js';
import { Router } from './Router.js';
import { ThemeProvider } from './ThemeProvider.js';
import { OldVersionDetector } from './OldVersionDetector.js';

export function App() {
  return (
    <ApolloClientProvider>
      <ThemeProvider>
        <OldVersionDetector />
        <Router />
      </ThemeProvider>
    </ApolloClientProvider>
  );
}

import { SentryLink } from 'apollo-link-sentry';

// eslint-disable-next-line import/no-unused-modules
export const getSentryApolloLinks = (sentryDsn) =>
  sentryDsn
    ? [
        new SentryLink({
          attachBreadcrumbs: {
            includeVariables: true,
          },
        }),
      ]
    : [];

import React from 'react';
import { string } from 'prop-types';
import { ClientBranding } from '@poly/constants';
import styled from 'styled-components';

export const ESFMX_LOGO_PATH = '/images/ESFMx.png';

const platformLogoImages = {
  [ClientBranding.POLY]: '/images/poly.png',
  [ClientBranding.ESFMX]: ESFMX_LOGO_PATH,
};

const Wrapper = styled.div`
  display: flex;
  font-size: 12px;
  margin: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const ImageLogo = styled.img`
  max-height: 25px;
`;

export function PlatformLogo({ branding }) {
  const image = platformLogoImages[branding];

  return (
    <Wrapper>
      <span>Powered by</span>
      <ImageLogo src={image} alt="platform-logo" />
    </Wrapper>
  );
}

PlatformLogo.propTypes = {
  branding: string.isRequired,
};

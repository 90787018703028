import * as R from 'ramda';
import styled from 'styled-components';
import { Form } from 'react-final-form';
import React, { useState } from 'react';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { WorkOrderPriority } from '@poly/constants';
import { FormField } from '@poly/admin-book';
import { isNilOrEmpty, trimString, validateEmail } from '@poly/utils';
import {
  internationalPhoneUtils,
  validateFormData,
  ifNotEmpty,
  commonFileValidators,
  validateFilesFunc,
} from '@poly/client-utils';
import { useUploadAttachment } from '@poly/client-utils/src/files/useUploadAttachment.js';

import { Text } from '../../components/Text.js';
import { Select } from '../../components/Select.js';
import { Button } from '../../components/Button.js';
import { OnChangeSetToLSFormValue } from './OnChangeSetToLSFormValue.js';
import { Input, PhoneInput, TextArea } from '../../components/Inputs.js';
import { ContactPhoneButton, CreatedRequest } from './CreatedRequest.js';
import { getLSRequestFormValues, postLSRequestFormValues } from '../utils.js';
import { FilePicker } from '../../components/FilePicker.js';
import { PropertySelect } from '../../components/PropertySelect.js';

const TwoColumnRow = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;
`;

export const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
`;

// getPriorityOptions :: [ProjectPriorityConfigEntry] -> [SelectOption]
const getPriorityOptions = R.compose(
  R.map(
    R.applySpec({
      value: R.prop('id'),
      label: ({ priority, amount, unit }) => `${priority} (${amount} ${unit})`,
    }),
  ),
  R.defaultTo([]),
);

const Wrapper = styled.div`
  text-align: center;
`;

const FormError = styled.p`
  color: #ff0000;
  font-size: 14px;
  margin-bottom: 15px;
  margin-top: 0;
  text-align: center;
`;

// isEmergencySelected :: FormValues -> Boolean
const isEmergencySelected = R.compose(
  R.equals(WorkOrderPriority.EMERGENCY),
  R.path(['priority', 'value']),
);

function EmergencyComponent({ branding }) {
  return (
    <Wrapper>
      <Text>
        Emergency requests are handled with the upmost priority. Please call our
        national service center
      </Text>
      <ContactPhoneButton branding={branding} />
    </Wrapper>
  );
}

EmergencyComponent.propTypes = {
  branding: string.isRequired,
};

function RequestFormComp({
  isClientRequest,
  projectPriorities,
  handleSubmit,
  branding,
  loading,
  isError,
  values,
  form,
  valid,
}) {
  const isEmergency = isEmergencySelected(values);

  const onSubmit = async (formData) => {
    if (valid) {
      form.pauseValidation();
    }

    await handleSubmit(formData);
    if (valid) {
      form.restart();
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <TwoColumnRow>
        {isClientRequest && (
          <FormField
            name="propertyId"
            Component={PropertySelect}
            additionalProps={{
              branding,
              required: true,
            }}
          />
        )}
        <FormField
          name="fullName"
          Component={Input}
          additionalProps={{
            branding,
            required: true,
            placeholder: 'Full Name',
          }}
        />
        <OnChangeSetToLSFormValue name="fullName" />
        {!isClientRequest && (
          <FormField
            name="priority"
            Component={Select}
            additionalProps={{
              branding,
              required: true,
              placeholder: 'Priority',
              options: getPriorityOptions(projectPriorities),
            }}
          />
        )}
      </TwoColumnRow>
      {isEmergency ? (
        <EmergencyComponent branding={branding} />
      ) : (
        <>
          <TwoColumnRow>
            <FormField
              name="email"
              Component={Input}
              additionalProps={{
                branding,
                placeholder: 'Email',
              }}
            />
            <OnChangeSetToLSFormValue name="email" />
            <FormField
              name="cellPhone"
              Component={PhoneInput}
              additionalProps={{
                branding,
                required: true,
                placeholder: 'Cell Phone #',
              }}
            />
            <OnChangeSetToLSFormValue name="cellPhone" />
          </TwoColumnRow>
          <FormField
            name="description"
            Component={TextArea}
            additionalProps={{
              branding,
              placeholder: 'Describe the issue',
            }}
          />
          <FormField
            name="attachments"
            Component={FilePicker}
            additionalProps={{
              branding,
            }}
            validate={validateFilesFunc(commonFileValidators)}
          />
          {isError && (
            <FlexCenter>
              <FormError>
                An error occurred while creating the request. Please contact
                support for assistance <ContactPhoneButton fontSize="14px" />
              </FormError>
            </FlexCenter>
          )}
          <FlexCenter>
            <Button branding={branding} loading={loading}>
              Create Request
            </Button>
          </FlexCenter>
        </>
      )}
    </form>
  );
}

RequestFormComp.propTypes = {
  handleSubmit: func.isRequired,
  branding: string.isRequired,
  loading: bool.isRequired,
  isError: bool,
  values: {},
  projectPriorities: arrayOf(
    shape({
      id: string,
      priority: string,
      amount: number,
      unit: string,
    }),
  ),
  form: shape({
    restart: func.isRequired,
    pauseValidation: func.isRequired,
  }),
  valid: bool,
  isClientRequest: bool,
};

const CREATE_REQUEST_PROJECT_MUTATION = gql`
  mutation CREATE_REQUEST_PROJECT_MUTATION($input: CreateRequestProjectInput!) {
    createRequestProject(input: $input) {
      projectId
    }
  }
`;

const {
  formattingInternationalPhone,
  findIsoCountryByNumber,
  getCountryByIso,
  isPhoneNumberEntered,
} = internationalPhoneUtils;

// prepareDataBeforeMutation :: FormData -> CreateRequestProjectInput
const prepareDataBeforeMutation = R.compose(
  R.dissoc('attachments'),
  R.evolve({
    email: R.when(R.isEmpty, R.always(null)),
    propertyId: R.prop('value'),
    priority: R.propOr(WorkOrderPriority.NORMAL, 'value'),
    cellPhone: R.ifElse(
      R.compose(R.lt(R.__, 7), R.length),
      R.always(null),
      formattingInternationalPhone,
    ),
  }),
);

// isPhoneNumberFilled :: String -> Boolean
const isPhoneNumberFilled = R.converge(isPhoneNumberEntered, [
  R.defaultTo(''),
  R.compose(
    R.prop('code'),
    getCountryByIso,
    findIsoCountryByNumber,
    R.defaultTo(''),
  ),
]);

const getValidationRules = (isClientRequest) => [
  {
    path: ['fullName'],
    validators: [[trimString, 'Full Name is required']],
  },
  {
    path: ['description'],
    validators: [[trimString, 'Description is required']],
  },
  {
    path: ['email'],
    validators: [[ifNotEmpty(validateEmail), 'Incorrect email']],
  },
  {
    path: ['cellPhone'],
    validators: [[isPhoneNumberFilled, 'Cell Phone is required']],
  },
  ...(isClientRequest
    ? [
        {
          path: ['propertyId'],
          validators: [[R.prop('value'), 'Property is required']],
        },
      ]
    : [
        {
          path: ['priority'],
          validators: [[R.prop('value'), 'Priority is required']],
        },
      ]),
];

const US_COUNTRY_CODE = '+1';

// getCreatedProjectId :: { createRequestProject: { projectId: String } } -> String
const getCreatedProjectId = R.path(['createRequestProject', 'projectId']);

const defaultCommonFormDataFields = { attachments: [], priority: null };

export function RequestForm({ branding, isClientRequest, projectPriorities }) {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [projectId, setProjectId] = useState('');
  const [commonFormDataFields, setCommonFormDataFields] = useState(
    defaultCommonFormDataFields,
  );

  const [createProject] = useMutation(CREATE_REQUEST_PROJECT_MUTATION);

  const localStorageInitialValues = getLSRequestFormValues();

  const initialValues = {
    ...commonFormDataFields,
    ...(localStorageInitialValues ? { ...localStorageInitialValues } : {}),
    ...(R.prop('cellPhone', localStorageInitialValues)
      ? {}
      : { cellPhone: US_COUNTRY_CODE }),
  };

  const uploadFile = useUploadAttachment();

  const onSubmit = async (formData) => {
    try {
      setIsLoading(true);

      let uploadedFilesIds = null;
      if (!isNilOrEmpty(formData?.attachments)) {
        const files = R.map(R.prop('upload'), formData.attachments);

        uploadedFilesIds = await Promise.all(
          files.map((file) => uploadFile(file)),
        );
      }

      const preparedValues = prepareDataBeforeMutation(formData);

      const { data } = await createProject({
        variables: {
          input: {
            ...preparedValues,
            ...(uploadedFilesIds ? { uploadedFilesIds } : {}),
          },
        },
      });

      const createdProjectId = getCreatedProjectId(data);

      postLSRequestFormValues(preparedValues);
      setIsError(false);

      if (createdProjectId) {
        setIsSubmitted(true);
        setProjectId(createdProjectId);
        setCommonFormDataFields(defaultCommonFormDataFields);
      } else {
        setCommonFormDataFields({
          priority: formData.priority,
          description: formData.description,
          attachments: formData.attachments,
        });
      }
    } catch (e) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  if (isSubmitted) {
    return (
      <CreatedRequest
        branding={branding}
        handleClick={() => setIsSubmitted(false)}
        projectId={projectId}
      />
    );
  }

  const validationRules = getValidationRules(isClientRequest);

  return (
    <Form
      initialValues={initialValues}
      render={RequestFormComp}
      onSubmit={onSubmit}
      validate={validateFormData(validationRules)}
      branding={branding}
      projectPriorities={projectPriorities}
      loading={isLoading}
      isError={isError}
      isClientRequest={isClientRequest}
      keepDirtyOnReinitialize
    />
  );
}

RequestForm.propTypes = {
  branding: string.isRequired,
  isClientRequest: bool,
  projectPriorities: arrayOf(
    shape({
      id: string,
      priority: string,
      amount: number,
      unit: string,
    }),
  ),
};
